<template>
  <div class="rounded-lg shadow-lg">
    <file-import-component type="company" />
  </div>
</template>

<script>
import FileImportComponent from "@/components/FileImportComponent.vue";

export default {
  components: {
    FileImportComponent,
  },
};
</script>

<style></style>
